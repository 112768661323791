import React from 'react';
import { Box, Container, Typography, Link as MuiLink, useMediaQuery } from '@mui/material';
import InstaButton from "./reusables/InstaButton";

const Updates = () => {
  const isMobile = useMediaQuery(theme => theme?.breakpoints.down('sm'));
  
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 6 }}>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 4,
            fontWeight: 600,
            fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
            color: '#0A2540'
          }}
        >
          Health Canada XML PM Requirements Update
        </Typography>
        
        <Typography 
          sx={{ 
            mb: 3,
            fontSize: '1.1rem',
            color: '#0A2540',
            fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          }}
        >
          Health Canada has announced the first phase of mandatory XML Product Monograph (XML PM) requirements. Starting July 18, 2025, XML PM will be a mandatory filing requirement for:
        </Typography>

        <Box sx={{ mb: 4, pl: 3 }}>
          <Typography 
            component="ul"
            sx={{ 
              fontSize: '1.1rem',
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            <li>New Drug Submissions (NDS)</li>
            <li>Extraordinary Use New Drug Submissions (EUNDS)</li>
          </Typography>
        </Box>

        <Typography 
          sx={{ 
            mb: 3,
            fontSize: '1.1rem',
            color: '#0A2540',
            fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          }}
        >
          This requirement will affect:
        </Typography>

        <Box sx={{ mb: 4, pl: 3 }}>
          <Typography 
            component="ul"
            sx={{ 
              fontSize: '1.1rem',
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            <li>Pharmaceutical drugs (prescription and certain non-prescription drugs)</li>
            <li>Biologic drugs (Schedule D)</li>
            <li>Radiopharmaceutical drugs (Schedule C)</li>
          </Typography>
        </Box>

        <Typography 
          sx={{ 
            mb: 4,
            fontSize: '1.1rem',
            color: '#0A2540',
            fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          }}
        >
          Once an XML PM has been authorized, it becomes a filing requirement for all subsequent submissions that include changes to the product monograph.
        </Typography>

        <Box sx={{ mb: 5 }}>
          <MuiLink 
            href="https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/announcements/change-filing-requirements-extensible-markup-language-product-monograph.html"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#1976d2',
              textDecoration: 'none',
              fontWeight: 600,
              fontSize: '1.1rem',
              display: 'inline-flex',
              alignItems: 'center',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
            Read the full Health Canada notice →
          </MuiLink>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 2,
              fontWeight: 600,
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              color: '#0A2540'
            }}
          >
            How InstaPM Can Help
          </Typography>
          <Typography 
            sx={{ 
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: '1.1rem',
              mb: 3,
              lineHeight: 1.6
            }}
          >
            InstaPM is a purpose-built platform for Regulatory Affairs professionals managing Product Monographs in Canada. We streamline the entire PM lifecycle by combining powerful XML capabilities with an intuitive interface, making it easy to create, manage, and submit compliant Product Monographs. As Health Canada transitions to mandatory XML requirements, InstaPM ensures your team can adapt quickly while significantly reducing the time and effort spent on PM management.
          </Typography>
          <Typography 
            sx={{ 
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: '1.1rem',
              mb: 2
            }}
          >
            Our comprehensive platform offers:
          </Typography>
          <Typography 
            component="ul"
            sx={{ 
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: '1.1rem',
              pl: 3
            }}
          >
            <li>Convert your existing Word-based PMs to Health Canada compliant XML format</li>
            <li>Create and edit PMs with an intuitive, user-friendly interface</li>
            <li>Real-time validation against Health Canada's strict XML requirements</li>
            <li>Built-in Health Canada controlled vocabulary to eliminate terminology errors</li>
            <li>Export your PM in multiple formats including XML, PDF, and Microsoft Word</li>
            <li>Integrated versioning allows to restore and compare previous versions of your PM</li>
            {/* <li>Collaborate securely with your team</li> */}
            {/* <li>Expert support and training throughout your transition</li> */}
          </Typography>
        </Box>

        <Box 
          sx={{ 
            mt: 6,
            pt: 5,
            pb: 5,
            px: 4,
            borderRadius: '16px',
            backgroundColor: '#F8FAFC',
            textAlign: 'center',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
            border: '1px solid #E5E7EB'
          }}
        >
          <Typography 
            variant="h5"
            sx={{ 
              mb: 3,
              fontWeight: 600,
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              color: '#0A2540'
            }}
          >
            Ready To Transition to InstaPM?
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'center', 
              alignItems: 'center',
              gap: 2,
              '& > button': {
                minWidth: isMobile ? '100%' : '180px'
              }
            }}
          >
            <InstaButton href="https://app.instapm.ca/demo">
              Start An Interactive Demo
            </InstaButton>
            <InstaButton outlined href="mailto:info@instapm.ca">
              Email Us to Learn More
            </InstaButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Updates; 