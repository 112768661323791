import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, useMediaQuery, Alert, Typography } from '@mui/material';
import InstaButton from "./reusables/InstaButton";
import logo from "./assets/images/InstaPm.png";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header = () => {
  let location = useLocation();
  const isUpdatesPage = location.pathname === "/updates";
  const [showAlert, setShowAlert] = useState(!isUpdatesPage);
  const isMobile = useMediaQuery(theme => theme?.breakpoints.down('sm'));

  useEffect(() => {
    setShowAlert(!isUpdatesPage)
  }, [isUpdatesPage])

  const demoButtonText = isMobile ? "Try InstaPM" : "Start An Interactive Demo"
  const demoButtonRightMargin = isMobile ? 0 : 2

  const isEventPage = location.pathname === "/event"
  return (
    <Box>
      {showAlert && (
        <Alert 
          severity="info" 
          onClose={() => setShowAlert(false)}
          sx={{
            borderRadius: '12px',
            backgroundColor: '#f5f9ff',
            '& .MuiAlert-icon': {
              color: '#1976d2',
              alignItems: 'flex-start',
              marginTop: '4px'
            },
            py: 3,
            px: 4,
            mx: 2,
            my: 2,
            '& .MuiAlert-message': {
              width: '100%'
            },
            '& .MuiAlert-action': {
              alignItems: 'flex-start',
              paddingTop: '4px'
            }
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600,
              mb: 1,
              fontSize: isMobile ? '1rem' : '1.25rem',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
              color: '#0A2540'
            }}
          >
            Update: Health Canada's XML PM Filing Requirements Are Here
          </Typography>
          <Typography 
            sx={{ 
              mb: 1,
              fontSize: isMobile ? '0.875rem' : '1rem',
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            <strong>Starting July 18, 2025</strong>, mandatory XML PM requirements will be introduced, impacting select submission filings.
          </Typography>
          <Typography 
            sx={{ 
              mb: 1.5,
              fontSize: isMobile ? '0.875rem' : '1rem',
              color: '#0A2540',
              fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            The clock is ticking—ensure your submissions meet the new requirements without delays. <strong>InstaPM makes the transition seamless, helping you convert, create, and submit XML PMs effortlessly.</strong>
          </Typography>
          <Link 
            to="/updates"
            style={{
              color: '#1976d2',
              textDecoration: 'none',
              fontWeight: 600,
              fontSize: isMobile ? '0.875rem' : '1rem',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            Learn More →
          </Link>
        </Alert>
      )}
      
      <Grid container>
        <Grid xs={3} item>
          <IconButton component={!isEventPage ? Link : null} to="/" disableRipple={true}>
            <img src={logo} width={"150rem"} alt="button icon" />
          </IconButton>
        </Grid>
        {!isEventPage && (
          <Grid item xs={9} sx={{display: "flex", justifyContent: 'flex-end'}}>
            <Box mr={demoButtonRightMargin}>
              <InstaButton outlined={!isMobile} href="https://app.instapm.ca/demo">{demoButtonText}</InstaButton>
            </Box>
            <Box hidden={isMobile}>
              <InstaButton href="https://app.instapm.ca/login">Login</InstaButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Header;
